<template>


  <div class="admin_panel">
      <div class="admin_panel--left">
        <p @click="$router.push('category_control')">Категорий</p>
        <p @click="$router.push('/admin_panel')">Товары</p>
        <p @click="$router.push('/stock')">Склад</p>
      </div>


      <div style="width: 100%">
        <router-view></router-view>
      </div>
  </div>
</template>


<script>
export default {
  name: "AdminControl",
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {

  }

};
</script>

<style>

p {
  padding: 0 !important;
  margin-bottom: 0 !important;
}

.admin_panel--left {
  background: #0069ff;
  height: 100vh;
  width: 250px;
  padding: 20px;
  p {
    cursor: pointer;
    color: white;
    margin-bottom: 30px !important;
    font-weight: bold;
  }
}

.admin_panel {
  display: flex;
  flex-direction: row;
}

</style>